<template>
  <div class="main">
    <van-nav-bar title="兑换成功" left-arrow @click-left="handleBack" />

    <div class="content">
      <van-image
        fit="cover"
        class="content-header"
        :src="require('../../static/images/v3/res.png')"
      />

      <div class="content-title">
        兑换成功
      </div>

      <div class="content-descr">
        赏金已到账，请重新{{ channel }}扫设备二维码去使用赏金。
      </div>

      <div class="content-submit">
        <van-button
              class="content-btn"
              color="#FF533C"
              @click="handleBack"
            >
              确定
            </van-button>
      </div>
    </div>

    <van-popup
      v-model="showResPopup"
      position="center"
      :close-on-click-overlay="false"
      :style="{ minHeight: '460px', width: '80%', backgroundColor: 'transparent' }"
      round
    >
      <div class="popup-result">
        <van-image
          fit="cover"
          class="popup-rules"
          :src="require('../../static/images/v3/popup.png')"
        />

        <div class="popup-bottom" @click="showResPopup = false"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  NavBar,
  Image,
  Button,
  Field,
  Popup,
  Step,
  Steps,
} from "vant";
import {
  memberInfo,
  queryCmccBalance,
  getProduct,
  placeOrder,
  dectOrder,
} from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },

  data() {
    return {
      showResPopup: true,
      channel: "",
    };
  },
  created() {
    if (/micromessenger/.test(window.navigator.userAgent.toLowerCase())) {
      this.channel = "使用微信"
    }else if (/AlipayClient/.test(window.navigator.userAgent)) {
      this.channel = "使用支付宝"
    }else {
      this.channel = ""
    }
  },
  methods: {


    handleBack() {
      let query = {}
      if (this.$route.query.channelCode) {
        query.channelCode = this.$route.query.channelCode
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id
      }

      if (this.$route.query.ddback) {
        query.ddback = this.$route.query.ddback
      }

      if (query.ddback) {
        window.location.href = `${decodeURIComponent(query.ddback)}`
      }else {
        this.$router.replace({
          path: "/exchange",
          query
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100vh;
  background-color: #fff;
}

:deep(.van-nav-bar__content) {
  background-color: #ff533c;
}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.popup-result {
  background-color: transparent;
  position: relative;
}
.popup-rules {

}

.popup-bottom {
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-header {
    width: 280px;
    padding-top: 80px;
  }

  &-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding: 15px 0;
  }

  &-descr {
    font-size: 13px;
    font-weight: 600;
    color: #666;
    padding: 15px 50px;
    text-align: center;
  }

  &-submit {
    padding-top: 30px;
  }

   &-btn {
    width: 250px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
}
</style>
